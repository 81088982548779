import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Conditions from "../../components/Conditions";
import ServicesHeader from "../../components/ServicesHeader";
import GeriatricMedicineInfo from "../../components/services/GeriatricMedicineInfo";

const GeriatricMedicine = () => {
  return (
    <Layout>
      <PageHeader text="Geriatric Medicine" />
      <ServicesHeader>
        If you’re an older adult, seeing a geriatric medicine specialist is
        essential to optimizing health and wellness. At Sunstate Medical
        Associates in Lake Mary, Florida, the expert internal medicine
        physicians offer geriatric health screening and advanced treatments to
        keep you healthy at every age, maximize mobility, and prevent or manage
        diseases. Schedule a geriatric medicine appointment by phone or online
        with Sunstate Medical Associates today.
      </ServicesHeader>
      <GeriatricMedicineInfo />
      <Conditions />
    </Layout>
  );
};

export default GeriatricMedicine;
