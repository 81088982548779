import React from "react";
import RequestButton from "../RequestButton";

const GeriatricMedicineInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">
          Geriatric Medicine Q & A
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What is geriatric medicine?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Geriatric medicine is medical care for older adults, as they often
          have a unique set of health care needs. Geriatric Medicine services
          available at Sunstate Medical Associates include:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Medicare wellness visits</li>
          <li>Health screenings</li>
          <li>Diagnostic testing</li>
          <li>Physical exams</li>
          <li>Medications</li>
          <li>Speech therapy</li>
          <li>Occupational therapy</li>
          <li>Physical therapy</li>
          <li>Dental screenings</li>
          <li>Vision and hearing screenings</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          Seeing a specialist at Sunstate Medical Associates offers you premium
          medical care at any age, from adulthood through the golden years and
          beyond.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What happens during Medicare wellness visits?
        </p>
        <p className="text-md text-gray-600 mb-2">
          During geriatic wellness exams, your Sunstate Medical Associates
          provider checks your blood pressure, pulse, other vital signs, and
          weight. They review your medical history including past and current
          diagnoses, medications, past surgeries, and family and personal
          history of disease.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Your provider completes a physical exam by evaluating your ears, eyes,
          nose, mouth, throat, breathing, abdomen, and heart rate. They can
          screen you for:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Hearing and vision problems</li>
          <li>High cholesterol</li>
          <li>High blood pressure</li>
          <li>Heart disease</li>
          <li>Diabetes</li>
          <li>Dementia</li>
          <li>Mobility problems</li>
          <li>Arthritis</li>
          <li>Osteoporosis</li>
          <li>Depression</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          To diagnose your condition they might suggest heart function tests,
          blood tests, urine tests, or imaging procedures like X-rays,
          ultrasound, CT scans, or MRIs.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          Which geriatric medicine treatments are available?
        </p>
        <p className="text-md text-gray-600 mb-3">
          After a physical exam, medical history review, and diagnostic testing,
          your doctor might recommend one of the following:
        </p>
        <p className="text-lg text-gray-700 mb-2">Lifestyle changes</p>
        <p className="text-md text-gray-600 mb-3">
          If you’re deficient in nutrients, are overweight or underweight, or
          have high blood pressure, osteoporosis, or another chronic disease,
          your doctor might recommend making diet, exercise, and other lifestyle
          changes.
        </p>
        <p className="text-lg text-gray-700 mb-2">Medications</p>
        <p className="text-md text-gray-600 mb-3">
          If you have an illness, injury, or chronic disease, your Sunstate
          Medical Associates provider may recommend you take oral medications or
          receive injections to better manage your condition.
        </p>
        <p className="text-lg text-gray-700 mb-2">Therapy</p>
        <p className="text-md text-gray-600 mb-3">
          Speech, occupational, and physical therapy can improve your mobility,
          independence, and overall quality of life. Your primary care physician
          lets you know if any of these therapies are right for you and can
          refer you to a highly skilled therapist.
        </p>
        <p className="text-lg text-gray-700 mb-2">Mobility devices</p>
        <p className="text-md text-gray-600 mb-3">
          If you have limited mobility due to age-related wear-and-tear, an
          injury, or a disease, your doctor lets you know if a brace, orthotics,
          cane, walker, or other mobility aid is right for you.
        </p>
        <p className="text-lg text-gray-700 mb-2">Specialist referrals</p>
        <p className="text-md text-gray-600 mb-3">
          In the case of a complex medical problem requiring surgical procedures
          or a specialist’s care, your doctor refers out to a qualified provider
          in your area.
        </p>
        <p className="text-md text-gray-600 mb-10">
          Maintain optimal health with the expert team at Sunstate Medical
          Associates. Schedule a geriatric medicine appointment over the phone
          or online today.
        </p>

        <RequestButton />
      </div>
    </div>
  );
};

export default GeriatricMedicineInfo;
